<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon dark>
            mdi-dots-vertical
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="item.click()"
        >
          <v-list-item-title>
            <v-icon left>
              {{ item.icon }}
            </v-icon>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <ModalEdit v-if="items[0].modal" @closeModal="closeModal" :task="task" />
    <ModalDelete v-if="items[1].modal" @closeModal="closeModal" :task="task" />
  </div>
</template>

<script>
import ModalDelete from "../modal/modalDelete.vue";
import ModalEdit from "../modal/modalEdit.vue";
export default {
  props: ["task"],
  components: { ModalEdit, ModalDelete },
  data: () => ({
    items: [
      {
        icon: "mdi-pencil",
        title: "Editar",
        modal: false,
        click() {
          this.modal = true;
        },
      },
      {
        icon: "mdi-trash-can",
        title: "Excluir",
        modal: false,
        click() {
          this.modal = true;
        },
      },
    ],
  }),
  methods: {
    closeModal() {
      this.items[0].modal = false;
      this.items[1].modal = false;
    },
  },
};
</script>

<style></style>
