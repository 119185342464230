import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tasks:[]
  },
  mutations: {
    handleAddStoreTask(state, title){
      if(title){
        state.tasks.push({
          id: new Date().getTime(),
          title: title, 
          done: false
        })
      }
    },
    handleRemoveStoreTask(state, id){
      if(id){
        state.tasks = state.tasks.filter(task => task.id !== id)
      }
    },
    handleEditTask(state, newTask){
      if(newTask.id){
        let edited = state.tasks.filter(task => task.id == newTask.id)[0]
        edited.title = newTask.title;
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
