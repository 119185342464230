<template>
  <div>
    <v-col cols="12">
      <v-text-field
        v-model="task_input"
        label="Adicionar Tarefa"
        outlined
        clearable
        @keyup.enter="handleAddTask"
      ></v-text-field>
    </v-col>
    <v-list flat subheader>
      <v-list-item-group multiple active-class="">
        <div v-for="(task, index) in $store.state.tasks" :key="index">
          <Task :task="task" />
        </div>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import Task from "../components/tasks/task.vue";
export default {
  name: "Home",

  components: {
    Task,
  },
  data() {
    return {
      task_input: null,
    };
  },
  methods: {
    handleAddTask() {
      this.$store.commit("handleAddStoreTask", this.task_input);
      this.task_input = null;
    },
  },
};
</script>
