<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Editar
        </v-card-title>
        <v-text-field
          class="px-3 pt-3"
          label="Título"
          placeholder="Informe o novo título"
          outlined
          v-model="title"
          @keyup.enter="handleEditTaskTitle"
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="$emit('closeModal')">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="handleEdit">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["task"],
  data: () => ({
    dialog: true,
    title: null,
  }),
  created() {
    this.title = this.task.title;
  },
  methods: {
    handleEdit() {
      let edited = { title: this.title, id: this.task.id };
      this.$store.commit("handleEditTask", edited);
      this.$emit("closeModal");
    },
  },
};
</script>

<style></style>
