<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Deseja excluir ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="$emit('closeModal')">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="handleDelete">
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["task"],
  data: () => ({
    dialog: true,
    title: null,
  }),
  created() {
    this.title = this.task.title;
  },
  methods: {
    handleDelete() {
      this.$store.commit("handleRemoveStoreTask", this.task.id);
      this.$emit("closeModal");
    },
  },
};
</script>

<style></style>
